import { ethers } from "ethers";
import env from "../env.json";

const Navigation = ({ account, setAccount }) => {
  const connectHandler = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: env.env === "prod" ? "0x89" : "0x13881" }], // '0x3830303031'
      });
    } catch (error) {
      console.log({error})
      if (env.env === "prod") {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x89", // '0x3830303031'
                blockExplorerUrls: ["https://polygonscan.com/"], // ['https://mumbai.polygonscan.com']
                chainName: "Polygon Mainnet", // 'Mumbai Testnet'
                nativeCurrency: {
                  decimals: 18,
                  name: "Polygon",
                  symbol: "MATIC",
                },
                rpcUrls: ["https://polygon-rpc.com"], // ['https://matic-mumbai.chainstacklabs.com']
              },
            ],
          });
        } catch (error) {
          // user rejects the request to "add chain" or param values are wrong, maybe you didn't use hex above for `chainId`?
          console.log(`wallet_addEthereumChain Error: ${error.message}`);
        }
      } else {
        try {
          await window.ethereum.request({
            id: 1,
            jsonrpc: "2.0",
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                chainName: "Polygon Testnet Mumbai",
                nativeCurrency: {
                  name: "tMATIC",
                  symbol: "tMATIC", // 2-6 characters long
                  decimals: 18,
                },
                blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
              },
            ],
          });
        } catch (error) {
          // user rejects the request to "add chain" or param values are wrong, maybe you didn't use hex above for `chainId`?
          console.log(`wallet_addEthereumChain Error: ${error.message}`);
        }
      }
    }

      const account = ethers.utils.getAddress(accounts[0]);

      setAccount(account);
    
  };

  return (
    <nav>
      <div className="nav__brand">
        <h1>AI NFT Generator</h1>
      </div>

      {account ? (
        <button type="button" className="nav__connect">
          {account.slice(0, 6) + "..." + account.slice(38, 42)}
        </button>
      ) : (
        <button type="button" className="nav__connect" onClick={connectHandler}>
          Connect
        </button>
      )}
    </nav>
  );
};

export default Navigation;
